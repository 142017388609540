<template>
  <b-card
    no-body
    class="custom-border-top-1px"
  >
    <b-card-header class="sticky-header">
      <b-badge
        variant="success"
        class="font-small-4 px-2 py-75"
      >
        HỒ SƠ
      </b-badge>
      <div class="d-flex align-items-center">
        <v-select
          :value="filter.month"
          class="select-size-sm mr-25"
          :options="$monthOptions"
          :searchable="false"
          :clearable="true"
          @input="(val) => $emit('update:filter', Object.assign(filter, {month: val}))"
        />
        <v-select
          :value="filter.year"
          class="select-size-sm"
          label="name"
          :options="$yearOptions"
          :searchable="false"
          :clearable="true"
          @input="(val) => $emit('update:filter', Object.assign(filter, {year: val}))"
        />
      </div>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          lg="8"
        >
          <div class="border border-radius-4 flex-grow-1 shadow">
            <div class="p-2 border-bottom">
              <div class="w-100 font-weight-bolder font-medium-1 d-flex flex-wrap">
                <div class="mb-50 flex-grow-1">
                  <span class="d-inline-block mr-1">Tổng số:</span>
                  <span class="text-primary">{{ data.total_count }}
                    <span class="font-weight-normal">(<span class="text-success">{{ data.total_count-data.contract_total_count }} HS</span>&nbsp;<small>&</small> <span class="text-danger">{{ data.contract_total_count }} HĐ</span>)</span>
                  </span>
                </div>
                <div class="flex-grow-1">
                  <span class="d-inline-block mr-1">Tổng giá trị:</span>
                  <span class="text-primary">{{ formatNumber(data.original_total) }} đ</span>
                </div>
              </div>
            </div>
            <div class="contract-report-wrap">
              <b-row class="w-100">
                <b-col
                  cols="12"
                  md="6"
                >
                  <div
                    v-for="(c, idx) in hosoByStatus"
                    :key="idx"
                    class="mb-75 cursor-pointer"
                    @click="goContractsList({typeDateFilter: 'default', statusFilter: c.value})"
                  >
                    <strong
                      class="minw-30px d-inline-block"
                      :class="'text-' + c.value"
                    >{{ c.count }}</strong>
                    <b-badge
                      class="font-small-3"
                      :class="'bg-light-'+c.value + ' text-'+c.value"
                    >
                      {{ $status.getName(c.value) }}
                    </b-badge>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <div
                    v-for="(c, idx) in hopdongByStatus"
                    :key="idx"
                    class="mb-75 cursor-pointer"
                    @click="goContractsList({typeDateFilter: 'default', statusFilter: c.value})"
                  >
                    <strong
                      class="minw-30px d-inline-block"
                      :class="'text-' + c.value"
                    >{{ c.count }}</strong>
                    <b-badge
                      class="font-small-3"
                      :class="'bg-light-'+c.value + ' text-'+c.value"
                    >
                      {{ $status.getName(c.value) }}
                    </b-badge>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
        <b-col
          cols="12"
          lg="4"
        >
          <vue-apex-charts
            type="donut"
            height="280"
            width="280"
            :options="data.chartOptions"
            :series="data.series"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BBadge, BCard, BCardBody, BCardHeader, BCol, BRow, VBTooltip,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
import vSelect from 'vue-select'

export default {
  components: {
    VueApexCharts,
    BRow,
    BCol,
    BBadge,
    BCardBody,
    BCardHeader,
    BCard,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      monthDateConfig: {
        altInput: true,
        allowInvalidPreload: true,
        allowInput: false,
        plugins: [
          monthSelectPlugin({
            shorthand: true,
            dateFormat: 'm-Y',
            altFormat: 'm-Y',
          }),
        ],
      },
    }
  },
  computed: {
    countByStatus() {
      if (!this.data || !this.data.count_by_status || !this.data.count_by_status.length) return []
      const { list } = this.$status
      const cbs = {}
      list.forEach(item => {
        cbs[item.value] = {
          ...item,
          count: 0,
        }
      })
      this.data.count_by_status.forEach(({
        count,
        status,
      }) => {
        cbs[status].count = count
      })
      return Object.values(cbs) || []
    },
    hosoByStatus() {
      if (!this.countByStatus || !this.countByStatus.length) return []
      return this.countByStatus.filter(item => item.type === 'hs') || []
    },
    hopdongByStatus() {
      if (!this.countByStatus || !this.countByStatus.length) return []
      return this.countByStatus.filter(item => item.type === 'hd') || []
    },
  },
  methods: {
    goContractsList(filter, withTimeFilter = true) {
      this.$router.push({
        name: 'contracts-list',
        query: {
          filter: JSON.stringify({
            rangeDateFilter: withTimeFilter ? this.updateRangeFilter() : '',
            ...filter,
          }),
        },
      })
    },
    updateRangeFilter() {
      const m = this.filter.month || ''
      const y = this.filter.year || ''
      let rangeDateFilter = ''
      if (!y) {
        rangeDateFilter = ' to '
      } else if (!m) {
        rangeDateFilter = `${y}-01-01 to ${y}-12-31`
      } else {
        const endDate = new Date(y, m, 0).getDate()
        rangeDateFilter = `${y}-${m}-01 to ${y}-${m}-${endDate}`
      }
      return rangeDateFilter
    },
  },
}
</script>

<style lang="scss">
.contract-report-wrap {
  padding: 2rem;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
}
</style>
