<template>
  <div>
    <reports-contract
      v-if="reportContractData"
      :filter.sync="contractFilter"
      :data="reportContractData"
    />

    <reports-best-user
      v-if="reportRevenueData"
      :filter.sync="bestUserFilter"
      :table-reports-best-user-data="tableReportsBestUserData"
    />

    <chart-total-amount-team
      :sum-department="sumDepartment"
      :filter="timeReportDepartment"
    />

    <chart-vertical-amount-team
      :sum-department="sumDepartmentCurrentMonth"
      :filter="chartVerticalFilter"
    />

    <reports-revenue
      v-if="reportRevenueData"
      :filter.sync="revenueFilter"
      :data="reportRevenueData"
    />

    <reports-multi-chart
      v-if="sumData"
      :filter.sync="revenueMultiChartFilter"
      :data="sumData"
    />

    <!--    <other-charts-->
    <!--      v-if="reportOtherChartsData"-->
    <!--      :data="reportOtherChartsData"-->
    <!--    />-->
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import reportStoreModule from '@/views/reports/reportStoreModule'
import ReportsMultiChart from '@/views/overview/ReportsMultiChart.vue'
import ReportsRevenue from '@/views/overview/ReportsRevenue.vue'
import ChartTotalAmountTeam from '@/views/overview/ChartTotalAmountTeam.vue'
import ChartVerticalAmountTeam from '@/views/overview/ChartVerticalAmountTeam.vue'
import metaStoreModule from '@/views/settings/parameters/metaStoreModule'
import ReportsContract from './ReportsContract.vue'
import ReportsBestUser from './ReportsBestUser.vue'
import useOverview from './useOverview'

export default {
  components: {
    ReportsContract,
    ReportsMultiChart,
    ReportsRevenue,
    ReportsBestUser,
    ChartTotalAmountTeam,
    ChartVerticalAmountTeam,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  watch: {
    contractData(n) {
      if (!n) return
      const { getColor, getName, list } = this.$status
      const rpContract = {
        labels: [],
        series: [],
        colors: [],
      }

      n.count_by_status.forEach(s => {
        rpContract.labels.push(getName(s.status))
        rpContract.series.push(parseFloat(s.count))
        rpContract.colors.push(getColor(s.status))
      })

      list.forEach(s => {
        if (!rpContract.labels.includes(s.label)) {
          rpContract.labels.push(s.label)
          rpContract.series.push(0)
          rpContract.colors.push(s.color)
          n.count_by_status.push({
            count: '0',
            status: s.value,
          })
        }
      })
      this.reportContractData = {
        ...n,
        series: rpContract.series,
        chartOptions: {
          legend: {
            show: false,
          },
          colors: rpContract.colors,
          dataLabels: {
            enabled: true,
            formatter(val) {
              // số hồ sơ
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                size: '30%',
                labels: {
                  show: true,
                  name: {
                    show: false,
                  },
                  value: {
                    show: true,
                    fontSize: '1.2rem',
                    fontFamily: 'Montserrat',
                    color: '#756bc9',
                    offsetY: 10,
                    fontWeight: 700,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`
                    },
                  },
                  total: {
                    show: true,
                  },
                },
              },
            },
          },
          labels: rpContract.labels,
          responsive: [
            {
              breakpoint: 1025,
              options: {
                chart: {
                  height: 240,
                },
              },
            },
            {
              breakpoint: 769,
              options: {
                chart: {
                  height: 200,
                },
              },
            },
            {
              breakpoint: 768,
              options: {
                chart: {
                  height: 300,
                },
              },
            },
          ],
        },
      }
    },
    revenueData(n) {
      if (!n) return
      this.reportRevenueData = {
        ...n,
        chartOptions: {
          // fill: {
          //   colors: '#F44336',
          // },
          chart: {
            id: 'chart-revenue',
            toolbar: {
              show: false,
            },
          },
          yaxis: {
            labels: {
              formatter: val => this.$formatVnd(val),
            },
          },
          xaxis: {
            categories: n.sum_contract_amount.map(item => item.time),
            type: 'datetime',
            labels: {
              format: 'MM-yyyy',
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 10,
              dataLabels: {
                position: 'top',
              },
            },
          },
          dataLabels: {
            enabled: true,
            formatter: val => this.$formatVnd(val, 3),
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ['#304758'],
            },
          },
        },
        series: [{
          name: 'Giá trị',
          data: n.sum_contract_amount.map(item => item.value),
        }],
      }
    },
    otherChartsData(n) {
      if (!n || !n.length) return
      this.reportOtherChartsData = n
    },
  },
  created() {
    this.fetchDashboard()
    this.fetchChart()
    this.fetchSum()
    this.fetchBestUser()
    this.fetchSumMeChart()
    this.fetchSumDepartmentCurrentMonth()
  },
  mounted() {
    window.__overview = this
  },
  methods: {
  },
  setup() {
    const REPORT_STORE_MODULE_NAME = 'report'
    const META_STORE_MODULE_NAME = 'meta'
    if (!store.hasModule(REPORT_STORE_MODULE_NAME)) store.registerModule(REPORT_STORE_MODULE_NAME, reportStoreModule)
    if (!store.hasModule(META_STORE_MODULE_NAME)) store.registerModule(META_STORE_MODULE_NAME, metaStoreModule)
    onUnmounted(() => {
      if (store.hasModule(REPORT_STORE_MODULE_NAME)) store.unregisterModule(REPORT_STORE_MODULE_NAME)
      if (store.hasModule(META_STORE_MODULE_NAME)) store.unregisterModule(META_STORE_MODULE_NAME)
    })

    return {
      ...useOverview(),
    }
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';
@import '~flatpickr/dist/plugins/monthSelect/style.css';

.per-page-selector {
  width: 90px;
}

.custom-head th {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.table .feather {
  min-width: 1rem;
}

.reports-contracts {
}

</style>
