<template>
  <b-card
    no-body
    class="custom-border-top-1px"
  >
    <b-card-header class="sticky-header">
      <b-badge
        variant="primary"
        class="font-small-4 px-2 py-75"
      >
        BIỂU ĐỒ NĂM
      </b-badge>

      <div class="d-flex gap-2">
        <div class="d-flex align-items-center">
          <span class="mr-50">Từ đầu {{ filter.monthStart ? 'tháng' : 'năm' }}</span>
          <v-select
            :value="filter.monthStart"
            class="select-size-sm mr-25"
            label="name"
            :options="$monthOptions"
            :searchable="false"
            :clearable="true"
            @input="(val) => $emit('update:filter', Object.assign(filter, {monthStart: val}))"
          />
          <v-select
            :value="filter.yearStart"
            class="select-size-sm"
            label="name"
            :options="$yearOptions"
            :searchable="false"
            :clearable="false"
            @input="(val) => $emit('update:filter', Object.assign(filter, {yearStart: val}))"
          />
        </div>
        <div class="d-flex align-items-center">
          <span class="mr-50">Đến cuối {{ filter.monthEnd ? 'tháng' : 'năm' }}</span>
          <v-select
            :value="filter.monthEnd"
            class="select-size-sm mr-25"
            label="name"
            :options="$monthOptions"
            :searchable="false"
            :clearable="true"
            @input="(val) => $emit('update:filter', Object.assign(filter, {monthEnd: val}))"
          />
          <v-select
            :value="filter.yearEnd"
            class="select-size-sm"
            label="name"
            :options="$yearOptions"
            :searchable="false"
            :clearable="false"
            @input="(val) => $emit('update:filter', Object.assign(filter, {yearEnd: val}))"
          />
        </div>
      </div>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          v-for="(chart, idx) in chartsData"
          :key="'overview-multi-chart-' + idx"
          cols="12"
          :md="chart.fullWidth ? '12' : '6'"
          class="mb-1"
        >
          <b-card
            no-body
            class="card-inside-card-shadow"
          >
            <b-card-header class="p-0">
              <b-badge
                small
                variant="light-primary"
                class="font-small-4 px-2 border-radius-0"
              >
                {{ chart.name }}
              </b-badge>
            </b-card-header>
            <vue-apex-charts
              type="bar"
              :height="chart.horizontal ? chart.series.length*300 : 300"
              :options="chart.chartOptions"
              :series="chart.series"
            />
          </b-card>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BBadge, BCard, BCardBody, BCardHeader, VBTooltip, BRow, BCol,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import vSelect from 'vue-select'
import { computed, ref } from '@vue/composition-api'

export default {
  components: {
    VueApexCharts,
    BBadge,
    BCardBody,
    BCardHeader,
    BCard,
    vSelect,
    BRow,
    BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      refreshKey: 1,
    }
  },
  watch: {
    data: {
      handler(n) {
        if (!n) return

        this.chartsData = [
          this.formatChartOption(this.getFlatDataChartTeamByContractCount()),
          this.formatChartOption(this.getFlatDataChartTeamByContractPrice()),
          this.formatChartOption(this.getFlatDataChartTeamByRevenue()),
          this.formatChartOption(this.getFlatDataChartPkhByContractCount()),
          this.formatChartOption(this.getFlatDataChartPkhByContractPrice()),
        ]

        this.refreshKey += 1
      },
    },
  },
  methods: {
    formatChartOption(opt = {}) {
      return {
        name: opt.name || 'Tiêu đề',
        fullWidth: opt.fullWidth,
        horizontal: opt.horizontal,
        chartOptions: {
          chart: {
            id: opt.id,
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: opt.horizontal, // -> biểu đồ cột ĐỨNG, đổi thành FALSE nếu là biểu đồ cột nằm
              dataLabels: {
                position: 'top',
              },
            },
          },
          dataLabels: {
            enabled: true,
            offsetX: opt.horizontal ? 35 : 0,
            offsetY: opt.horizontal ? 0 : -20,
            style: {
              fontSize: '11px',
              colors: ['#000'],
            },
            formatter: opt.formatDataLabel || (val => val),
          },
          colors: ['#28c76f', '#ea5455', '#ff993c'],
          stroke: {
            show: true,
            width: 1,
            colors: ['#fff'],
          },
          tooltip: {
            shared: true,
            intersect: false,
            enabled: opt.tooltip ? opt.tooltip.enabled : true,
          },
          xaxis: {
            categories: opt.xaxisCategories,
            labels: {
              formatter: opt.horizontal ? opt.formatDataLabel : (val => val),
            },
          },
          yaxis: {
            categories: opt.yaxisCategories,
            labels: {
              formatter: opt.formatStep || (val => val),
            },
          },
        },
        series: opt.series,
        labels: opt.labels,
      }
    },
    getFlatDataChartTeamByContractCount() {
      const hs = this.data.report_department_HS?.departments || []
      const hd = this.data.report_department_HD?.departments || []
      const hshd = this.data.report_deparment || []
      const hashById = {}

      hshd.forEach(item => {
        hashById[item.department_id] = hashById[item.department_id] || item
      })
      hs.forEach(item => {
        Object.keys(item).forEach(key => {
          hashById[item.department_id] = hashById[item.department_id] || {}
          hashById[item.department_id][`hs_${key}`] = item[key]
        })
      })
      hd.forEach(item => {
        Object.keys(item).forEach(key => {
          hashById[item.department_id] = hashById[item.department_id] || {}
          hashById[item.department_id][`hd_${key}`] = item[key]
        })
      })

      const teams = Object.values(hashById).filter(item => item.department_name !== 'Tổng')

      return {
        name: 'Tổng HS/HĐ đội',
        id: 'chart-hs-hd-doi',
        fullWidth: false,
        formatDataLabel: this.formatNumber,
        formatStep: this.formatNumber,
        horizontal: false,
        xaxisCategories: teams.map(item => item.department_name || 'Tên đội'),
        yaxisCategories: ['Tổng HS', 'Tổng HĐ', 'Tổng HS thu được'],
        series: [
          { name: 'Tổng HS', data: teams.map(item => item.hs_contract_count || 0) },
          { name: 'Tổng HĐ', data: teams.map(item => item.hd_contract_count || 0) },
          { name: 'Tổng HS thu được', data: teams.map(item => item.is_collection || item.hs_is_collection || item.hd_is_collection || 0) },
        ],
        labels: ['test 1', 'test 2', 'test 3'],
      }
    },
    getFlatDataChartTeamByContractPrice() {
      const hs = this.data.report_department_HS?.departments || []
      const hd = this.data.report_department_HD?.departments || []
      const hashById = {}

      hs.forEach(item => {
        Object.keys(item).forEach(key => {
          hashById[item.department_id] = hashById[item.department_id] || {}
          hashById[item.department_id][`hs_${key}`] = item[key]
        })
      })
      hd.forEach(item => {
        Object.keys(item).forEach(key => {
          hashById[item.department_id] = hashById[item.department_id] || {}
          hashById[item.department_id][`hd_${key}`] = item[key]
        })
      })

      const teams = Object.values(hashById).filter(item => item.department_name !== 'Tổng')

      return {
        name: 'Giá trị HS/HĐ đội',
        id: 'chart-gia-tri-hs-hd-doi',
        formatDataLabel: value => this.$formatVnd(value, 3),
        formatStep: this.$formatVndForChart,
        fullWidth: false,
        horizontal: false,
        xaxisCategories: teams.map(item => item.hs_department_name || 'Tên đội'),
        yaxisCategories: ['Tổng giá trị HS', 'Tổng giá trị HĐ'],
        series: [
          { name: 'Tổng giá trị HS', data: teams.map(item => item.hs_total_sum || 0) },
          { name: 'Tổng giá trị HĐ', data: teams.map(item => item.hd_total_sum || 0) },
        ],
        labels: ['test 1', 'test 2'],
      }
    },
    getFlatDataChartTeamByRevenue() {
      const hshd = this.data.report_deparment || []

      const teams = hshd.filter(item => item.department_name !== 'Tổng')

      return {
        name: 'Doanh thu đội',
        id: 'chart-doanh-thu-doi',
        formatDataLabel: value => this.$formatVnd(value, 3),
        formatStep: this.$formatVndForChart,
        fullWidth: false,
        horizontal: false,
        xaxisCategories: teams.map(item => item.department_name || 'Tên đội'),
        yaxisCategories: ['Doanh thu đội', 'Doanh thu cty'],
        series: [
          { name: 'Doanh thu đội', data: teams.map(item => item.total_amount_for_team || 0) },
          { name: 'Doanh thu cty', data: teams.map(item => ((item.total_amount_buy_original || 0) - (item.total_amount_for_team || 0))) },
        ],
        labels: ['test 1', 'test 2'],
      }
    },
    getFlatDataChartPkhByContractCount() {
      const users = (this.data.report_user || []).sort((a, b) => {
        const aName = a.user_name.split(' ').pop()
        const bName = b.user_name.split(' ').pop()
        return aName > bName ? 1 : -1
      })

      return {
        name: 'Tổng HS/HĐ phòng khách hàng',
        id: 'chart-tong-hs-pkh',
        formatDataLabel: this.formatNumber,
        formatStep: false,
        fullWidth: true,
        horizontal: true,
        tooltip: { enabled: false },
        xaxisCategories: users.map(item => this.shortUsername(item.user_name)),
        yaxisCategories: ['Tổng HS', 'Tổng HĐ', 'Số HĐ đòi được'],
        series: [
          { name: 'Tổng HS', data: users.map(item => item.count_total || 0) },
          { name: 'Tổng HĐ', data: users.map(item => item.contract_total || 0) },
          { name: 'Số HĐ đòi được', data: users.map(item => item.debt_count || 0) },
        ],
        labels: ['test 1', 'test 2'],
      }
    },
    getFlatDataChartPkhByContractPrice() {
      const users = (this.data.report_user || []).sort((a, b) => {
        const aName = a.user_name.split(' ').pop()
        const bName = b.user_name.split(' ').pop()
        return aName > bName ? 1 : -1
      })

      return {
        name: 'Giá trị HS/HĐ phòng khách hàng',
        id: 'chart-tong-gia-tri-hs-pkh',
        formatDataLabel: value => this.$formatVnd(value, 3),
        formatStep: false,
        fullWidth: true,
        horizontal: true,
        tooltip: { enabled: false },
        xaxisCategories: users.map(item => this.shortUsername(item.user_name)),
        yaxisCategories: ['Giá trị HS', 'Giá trị HĐ', 'Giá trị HĐ đòi được'],
        series: [
          { name: 'Giá trị HS', data: users.map(item => item.sum_origin || 0) },
          { name: 'Giá trị HĐ', data: users.map(item => item.hd_sum_origin || 0) },
          { name: 'Giá trị HĐ đòi được', data: users.map(item => item.debt_total || 0) },
        ],
        labels: ['test 1', 'test 2'],
      }
    },
  },
  setup(props) {
    const teams = computed(() => props.data.report_deparment || [])
    const pkhs = computed(() => props.data.report_user || [])

    const chartsData = ref([])

    const chartOptionTeamByContractCount = ref({})
    const chartOptionTeamByContractPrice = ref({})
    const chartOptionTeamByRevenue = ref({})
    const chartOptionPkhByContractCount = ref({})
    const chartOptionPkhByContractPrice = ref({})

    return {
      teams,
      pkhs,
      chartsData,
      chartOptionTeamByContractCount,
      chartOptionTeamByContractPrice,
      chartOptionTeamByRevenue,
      chartOptionPkhByContractCount,
      chartOptionPkhByContractPrice,
    }
  },
}
</script>

<style lang="scss">
</style>
