import {
  computed, reactive, ref, watch,
} from '@vue/composition-api'
import http from '@/global/http'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import { getBeforeMonth } from '@/utils/helpers'

export default function useOverview() {
  const toast = useToast()

  const fromMeta = ref(null)
  const toMeta = ref(null)

  const timeReportDepartment = computed(() => {
    const monthToReal = new Date(toMeta.value?.data?.meta_value).setDate(0)
    const monthFrom = new Date(fromMeta.value?.data?.meta_value).getMonth() + 1
    const yearFrom = new Date(fromMeta.value?.data?.meta_value).getFullYear()
    const monthTo = new Date(monthToReal).getMonth() + 1
    const yearTo = new Date(monthToReal).getFullYear()
    return {
      monthFrom: String(monthFrom).padStart(2, '0'),
      yearFrom,
      monthTo: String(monthTo).padStart(2, '0'),
      yearTo,
    }
  })
  const contractData = ref(null)
  const revenueData = ref(null)
  const otherChartsData = ref(null)

  const reportContractData = ref(null)
  const reportRevenueData = ref(null)
  const reportOtherChartsData = ref(null)
  const reportBestUserData = ref(null)

  const pkhs = ref([])

  const sumData = ref({})
  const sumDepartment = ref([])
  const sumDepartmentCurrentMonth = ref([])
  const reportDeparment = computed(() => sumData.value.report_deparment || [])
  const departmentsData = computed(() => reportDeparment.value.filter(d => d.department_id).map(d => ({
    id: d.department_id,
    name: d.department_name,
  })))
  const tableReportsBestUserData = computed(() => departmentsData.value.map(d => {
    const data = (reportBestUserData.value[d.id] || {}).voted_user || {}
    const mostVote = Math.max(...Object.values(data).map(v => v.total_voted), 0)
    const userWithMostVote = Object.values(data).filter(v => v.total_voted === mostVote)
    const votes = (reportBestUserData.value[d.id] || {}).votes || []
    return {
      voted_user: data,
      department_id: d.id,
      department_name: d.name,
      userWithMostVote,
      votes,
    }
  }))
  const teams = ref([])

  const m = new Date().getMonth() + 1
  const y = new Date().getFullYear()
  const contractFilter = reactive({
    month: ref(m > 9 ? m : `0${m}`),
    year: ref(y),
  })
  const { month, year } = getBeforeMonth()
  const bestUserFilter = reactive({
    month,
    year,
  })
  const revenueFilter = reactive({
    year: ref(y),
  })
  const revenueMultiChartFilter = reactive({
    monthStart: ref(0),
    yearStart: ref(y),
    monthEnd: ref(m),
    yearEnd: ref(y),
  })

  const totalAmountTeamChartFilter = reactive({
    monthStart: ref(0),
    yearStart: ref(y),
    monthEnd: ref(m),
    yearEnd: ref(y),
  })

  const chartVerticalFilter = reactive({
    month: ref(m > 9 ? m : `0${m}`),
    year: ref(y),
  })

  const fetchDashboard = (filter = {}) => {
    const {
      year,
      month,
    } = contractFilter

    if (!year && !month) {
      return http.api(store.dispatch('report/dashboard'), res => {
        contractData.value = res.data
      })
    }
    if (!year) {
      return null
    }

    const from = !month ? new Date(year, 0, 1)
      : new Date(year, parseFloat(month - 1), 1)
    const to = !month ? new Date(year, 12, 0)
      : new Date(year, month, 0, 23, 59, 59)

    const queryString = http.buildQuery({
      from: http.getISODate(filter.from || from),
      to: http.getISODate(filter.to || to),
    }) || ''
    return http.api(store.dispatch('report/dashboard', queryString), res => {
      contractData.value = res.data
    })
  }

  const fetchSum = filter => {
    const from = new Date(revenueMultiChartFilter.yearStart, revenueMultiChartFilter.monthStart ? revenueMultiChartFilter.monthStart - 1 : 0, 2)
    const to = new Date(revenueMultiChartFilter.yearEnd, revenueMultiChartFilter.monthEnd ? revenueMultiChartFilter.monthEnd : 12, 1)
    const queryString = http.buildQuery(filter || {
      from: http.getISODate(from),
      to: http.getISODate(to),
    }) || ''

    return http.api(store.dispatch('report/sum', queryString), res => {
      sumData.value = (res.data || {})
      teams.value = res.data.report_deparment || []
      pkhs.value = res.data.report_user || []
    })
  }

  const fetchChart = filter => {
    const from = new Date(revenueFilter.year, 0, 1)
    const to = new Date(revenueFilter.year, 12, 0)
    const queryString = http.buildQuery(filter || {
      from: http.getISODate(from),
      to: http.getISODate(to),
    }) || ''
    return http.api(store.dispatch('report/chart', queryString), res => {
      revenueData.value = res.data
    })
      .catch(e => {
        toast({
          component: ToastificationContent,
          props: {
            title: e,
            variant: 'danger',
          },
        })
        throw e
      })
  }

  const fetchBestUser = filter => {
    const {
      month,
      year,
    } = bestUserFilter
    const queryString = http.buildQuery({
      page: 0,
      perpage: 1000,
      month,
      year,
    }) || ''
    http.api(store.dispatch('report/fetchAllDepartmentVote', queryString), res => {
      reportBestUserData.value = res.data.reduce((acc, cur) => {
        if (!acc[cur.department_id]) {
          acc[cur.department_id] = {}
          acc[cur.department_id].voted_user = {}
        }
        acc[cur.department_id].voted_user[cur.voted_user_id] = {
          voted_user: cur.voted_user,
          voted_user_id: cur.voted_user_id,
          total_voted: ((acc[cur.department_id].voted_user[cur.voted_user_id] || {}).total_voted || 0) + 1,
        }
        acc[cur.department_id].votes = (acc[cur.department_id].votes || []).concat(cur)
        return acc
      }, {})
    })
  }

  const fetchSumDepartmentCurrentMonth = async () => {
    const {
      year,
      month,
    } = chartVerticalFilter

    if (!year && !month) {
      http.api(store.dispatch('report/sumDepartment'), res => {
        sumDepartmentCurrentMonth.value = ((res.data || {}).report_deparment || []).filter(el => el.department_name !== 'Tổng')
      })
    }
    if (!year) {
      return null
    }

    const from = !month ? new Date(year, 0, 1)
      : new Date(year, parseFloat(month - 1), 1)
    const to = !month ? new Date(year, 12, 0)
      : new Date(year, month, 0, 23, 59, 59)

    const queryString = http.buildQuery({
      from: http.getISODate(from),
      to: http.getISODate(to),
    }) || ''
    http.api(store.dispatch('report/sumDepartment', queryString), res => {
      sumDepartmentCurrentMonth.value = ((res.data || {}).report_deparment || []).filter(el => el.department_name !== 'Tổng')
    })
  }

  const getQuery = () => http.buildQuery({
    from: fromMeta.value.data?.meta_value,
    to: toMeta.value.data?.meta_value,
  }) || ''

  const fetchSumMeChart = async () => {
    fromMeta.value = await http.api(store.dispatch('meta/fetchMeta', { name: 'from_report_department' }))
    toMeta.value = await http.api(store.dispatch('meta/fetchMeta', { name: 'to_report_department' }))
    const queryString = getQuery()
    return http.api(store.dispatch('report/sumDepartment', queryString), res => {
      sumDepartment.value = ((res.data || {}).report_deparment || []).filter(el => el.department_name !== 'Tổng')
    })
  }

  watch([
    computed(() => contractFilter.month),
    computed(() => contractFilter.year),
  ], () => {
    fetchDashboard()
  })
  watch([
    computed(() => chartVerticalFilter.month),
    computed(() => chartVerticalFilter.year),
  ], () => {
    fetchSumDepartmentCurrentMonth()
  })
  watch([
    computed(() => bestUserFilter.month),
    computed(() => bestUserFilter.year),
  ], () => {
    fetchBestUser()
  })

  watch([
    computed(() => revenueFilter.year),
  ], () => {
    fetchChart()
  })

  watch([
    computed(() => totalAmountTeamChartFilter.yearStart),
    computed(() => totalAmountTeamChartFilter.yearEnd),
    computed(() => totalAmountTeamChartFilter.monthStart),
    computed(() => totalAmountTeamChartFilter.monthEnd),
  ], () => {
    fetchSumMeChart()
  })
  watch([
    computed(() => revenueMultiChartFilter.yearStart),
    computed(() => revenueMultiChartFilter.yearEnd),
    computed(() => revenueMultiChartFilter.monthStart),
    computed(() => revenueMultiChartFilter.monthEnd),
  ], () => {
    fetchSum()
  })

  setTimeout(() => {
    otherChartsData.value = [
      {
        name: 'Tiêu đề của chart 1',
        fullWidth: false, // -> 1/2 width, TRUE thì fullwidth
        chartOptions: {
          chart: {
            id: 'id-chart-1',
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false, // -> biểu đồ cột ĐỨNG, đổi thành FALSE nếu là biểu đồ cột nằm
              dataLabels: {
                position: 'top',
              },
            },
          },
          dataLabels: {
            enabled: true,
            offsetX: 0,
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ['#000'],
            },
          },
          stroke: {
            show: true,
            width: 1,
            colors: ['#fff'],
          },
          tooltip: {
            shared: true,
            intersect: false,
          },
          xaxis: {
            categories: ['Đội 1', 'Đội 2', 'Đội 3', 'Đội 4', 'Đội 5', 'Đội 6', 'Đội 7'],
          },
          yaxis: {
            categories: ['Năm 2021', 'Năm 2022'],
            // labels: {
            //   formatter: val => http.formatNumber(val),
            // },
          },
        },
        series: [
          { name: 'Năm 2021', data: [20, 55, 41, 64, 120, 95, 21] },
          { name: 'Năm 2022', data: [53, 50, 33, 52, 13, 1500, 74] },
        ],
        labels: ['test 1', 'test 2'],
      },
    ]
  }, 100)

  return {
    month: m,
    fetchSum,
    fetchChart,
    fetchDashboard,
    fetchBestUser,
    fetchSumMeChart,
    fetchSumDepartmentCurrentMonth,
    reportBestUserData,
    reportDeparment,
    departmentsData,
    sumData,
    sumDepartment,
    sumDepartmentCurrentMonth,
    revenueData,
    contractData,
    otherChartsData,
    reportContractData,
    reportRevenueData,
    reportOtherChartsData,
    timeReportDepartment,
    tableReportsBestUserData,
    teams,
    pkhs,
    contractFilter,
    bestUserFilter,
    revenueFilter,
    revenueMultiChartFilter,
    totalAmountTeamChartFilter,
    chartVerticalFilter,
  }
}
