<template>
  <b-card
    no-body
    class="custom-border-top-1px"
  >
    <b-card-header class="sticky-header">
      <b-badge
        variant="danger"
        class="font-small-4 px-2 py-75"
      >
        GIÁ TRỊ HỢP ĐỒNG
      </b-badge>

      <div>
        <v-select
          :value="filter.year"
          class="select-size-sm"
          label="name"
          :options="$yearOptions"
          :searchable="false"
          :clearable="false"
          @input="(val) => $emit('update:filter', Object.assign(filter, {year: val}))"
        />
      </div>
    </b-card-header>
    <b-card-body>
      <vue-apex-charts
        type="bar"
        height="300"
        :options="data.chartOptions"
        :series="data.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BBadge, BCard, BCardBody, BCardHeader, VBTooltip,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import vSelect from 'vue-select'

export default {
  components: {
    VueApexCharts,
    BBadge,
    BCardBody,
    BCardHeader,
    BCard,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    filter: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style lang="scss">
</style>
