<template>
  <b-card
    no-body
    class="custom-border-top-1px"
  >
    <b-card-header class="sticky-header">
      <b-badge
        variant="warning"
        class="font-small-4 px-2 py-75"
      >
        NHÂN VIÊN XUẤT SẮC NHẤT {{ filter.month }}/{{ filter.year }}
      </b-badge>

      <div class="d-flex align-items-center">
        <v-select
          :value="filter.month"
          class="select-size-sm mr-25"
          :options="$monthOptions"
          :searchable="false"
          :clearable="true"
          @input="(val) => $emit('update:filter', Object.assign(filter, {month: val}))"
        />
        <v-select
          :value="filter.year"
          class="select-size-sm"
          label="name"
          :options="$yearOptions"
          :searchable="false"
          :clearable="true"
          @input="(val) => $emit('update:filter', Object.assign(filter, {year: val}))"
        />
      </div>
    </b-card-header>
    <b-card-body>
      <b-table
        class="position-relative text-center table-col-max-width-100 table-col-min-width-100"
        responsive
        bordered
        small
        striped
        primary-key="id"
        show-empty
        empty-text="Không có kết quả nào"
        :items="tableReportsBestUserData"
        :fields="tableColumns"
      >
        <template #cell(department_name)="data">
          <span
            class="cursor-pointer"
            @click="goReportTeamDetail(data.item)"
          >{{ data.item.department_name }}</span>
        </template>
        <template #cell(nhan_vien)="data">
          <div
            v-if="data.item.userWithMostVote.length"
            class="d-flex flex-column gap-1"
          >
            <span
              v-for="(user, index) in data.item.userWithMostVote"
              :key="index"
              class="text-primary font-weight-bold"
            >{{ `${user.voted_user} (${(((data.item.voted_user || {})[user.voted_user_id]) || {}).total_voted || 0} bình chọn)` }}</span>
          </div>
          <span v-else>-</span>
        </template>
        <template #cell(binh_chon)="data">
          <div
            v-if="data.item.votes.length"
            class="d-flex flex-column gap-1"
          >
            <span
              v-for="(user, index) in data.item.votes"
              :key="index"
              class="sl-12"
            >
              <span class="text-primary font-weight-bold">{{ user.voter }}</span> bình chọn
              <span
                class="font-weight-bold"
                :class="classText(data.item.userWithMostVote, user.voted_user_id)"
              >{{ `${user.voted_user}` }}</span>
            </span>
          </div>
          <span v-else>-</span>
        </template>
      </b-table>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BBadge, BCard, BCardBody, BCardHeader, VBTooltip, BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BTable,
    BBadge,
    BCardBody,
    BCardHeader,
    BCard,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    tableReportsBestUserData: {
      type: Array,
      default: () => [],
    },
    filter: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    tableColumns() {
      return [
        {
          label: 'ĐPC',
          key: 'department_name',
          tdClass: 'text-nowrap text-uppercase text-primary pt-50 pb-50',
          stickyColumn: true,
        },
        {
          label: 'Nhân viên',
          key: 'nhan_vien',
        },
        {
          label: 'Bình chọn',
          key: 'binh_chon',
        },
      ]
    },
  },
  methods: {
    classText(userWithMostVote, votedUserId) {
      const user = userWithMostVote.find(u => u.voted_user_id === votedUserId)
      return user ? 'text-danger' : 'text-primary'
    },
    goReportTeamDetail(item) {
      const q = this.$route.query
      const monthFilter = this.filter.month || ''
      const yearFilter = this.filter.year || ''
      this.$router.push({
        name: 'reports-team',
        params: { id: item.department_id },
        query: {
          name: item.department_name,
          monthFilter,
          yearFilter,
          t: new Date().getTime(),
        },
      })
    },
  },
}
</script>

  <style lang="scss">
  </style>
